import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthenticationService} from "../services/authentication.service";
import {StorageService} from "../services/storage.service";
import {AlertsService, CLModalType, CLToastType, ModalService} from "@clavisco/alerts";
import {Structures} from "@clavisco/core";
import {IToken} from "../interfaces/i-token";
import {IPermissionsSelectedModel} from "../interfaces/i-permission-by-rol";
import {GeneralConfigsService} from "../services/generalconfigs.service";

@Injectable({
  providedIn: 'root'
})
export class CheckPermissionsGuard implements CanActivate {
  constructor(
    private generalconfig:GeneralConfigsService,
    private alertService: AlertsService
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let requiredPermissions: string = this.GetRequiredPermission(state.url);
    let havePermission: boolean = this.generalconfig.CheckPermission(requiredPermissions);
    if (!havePermission) {
      this.alertService.Toast({ message: 'Acceso denegado, no posee permisos para realizar esta acción', type: CLToastType.ERROR })
    }
    return havePermission;
  }

  GetRequiredPermission(route: string) {
    //Permiso requerido para visualizar cada opción en el menú
    let requiredPermission: string = '';

    switch (route) {
      case '/users':
        requiredPermission = "M_Users";
        break;

      case '/roles':
        requiredPermission = "M_Roles";
        break;

      case '/permissions':
        requiredPermission = "M_AssignPerm";
        break;
    }
    return requiredPermission;
  }


}
