import { Injectable } from '@angular/core';
import {CLPrint, Repository, Structures} from "@clavisco/core";
import {Router} from "@angular/router";
import {IToken} from "../interfaces/i-token";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public currentUserSubject: BehaviorSubject<any>;
  public currentPermissionsSubject!: BehaviorSubject<any>;
  constructor(private router: Router) {
    this.currentUserSubject = new BehaviorSubject<any>(Repository.Behavior.GetStorageObject('currentUser'));
    this.currentPermissionsSubject = new BehaviorSubject<any>(Repository.Behavior.GetStorageObject('permissions'));
  }

  GetCurrentUser(): IToken {
    const TOKEN: IToken | null = Repository.Behavior.GetStorageObject<any | null>('currentUser');
    if (TOKEN && TOKEN.userName) {
      const userObj = JSON.parse(TOKEN.userName);
      Repository.Behavior.SetStorage<IToken>(userObj,"currentUser");
      return userObj;
    } else {
      return {} as any;
    }
  }

  public isAuthenticated(): boolean {

    const session = Repository.Behavior.GetStorageObject<IToken>('currentUser');
    return session ? new Date(session.expires) >= this.GetUTCNow() : false;
  }

  private GetUTCNow(): Date {
    const dateNow: Date = new Date();
    return new Date(dateNow.getUTCFullYear(), dateNow.getUTCMonth(), dateNow.getUTCDate(), dateNow.getUTCHours(), dateNow.getUTCMinutes(), dateNow.getUTCSeconds());
  }

  public logout():void {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('permissions');
    this.currentUserSubject.next(null);
    //this.tabComponentService.clearData();
    this.currentPermissionsSubject.next(null);
    this.router.navigateByUrl('/login');
  }
  //public ValidateRol(role: string) : boolean{
    //return this.GetCurrentUser().IsSuperUser || this.GetCurrentUser().RoleName == role
 // }
}
