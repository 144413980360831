import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpStatusCode, HttpResponse
} from '@angular/common/http';
import {catchError, map, Observable, throwError} from 'rxjs';
import {StorageService} from "../../core/services/storage.service";
import {ModalService} from "@clavisco/alerts";
import {LoginService} from "@clavisco/login";
import {Router} from "@angular/router";
import {Repository} from "@clavisco/core";
import {AuthenticationService} from "../../core/services/authentication.service";
import {environment} from "../../../environments/environment";
import {IToken} from "../../core/interfaces/i-token";
import {formatDate} from "@angular/common";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private storage: StorageService,
              private modalService: ModalService,
              private loginService: LoginService,
              private router: Router,
              private authService: AuthenticationService) {}

  //Se encarga de realizar el manejo de la sesion
  /**
   * Intercepta las solicitudes HTTP y maneja las respuestas (su uso principal es en el manejo de la sesion).
   * @param request La solicitud HTTP.
   * @param next El próximo controlador HTTP.
   * @returns Un observable de eventos HTTP.
   */
  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if(req.url.includes('token'))
    {
      return next.handle(req);
    }

    let userToken: IToken | null = Repository.Behavior.GetStorageObject<IToken>('currentUser');

    let clonedRequest: HttpRequest<unknown> = req.clone();

    if(userToken)
    {
      let headers = clonedRequest.headers.set('Authorization', `Bearer ${userToken.access_token}`);

      clonedRequest = clonedRequest.clone({headers});
    }

    let headers = clonedRequest.headers
      .set('cl-ui-request-timestamp', formatDate(new Date(), 'dd-MM-yyyy hh:mm:ss.SSS a', 'en'));

    clonedRequest = clonedRequest.clone({headers});

    // Valido que no tenga el header que indica si tiene archivos para no enviar el content type
    if(!clonedRequest.headers.has('Request-With-Files'))
    {
      let headers = clonedRequest.headers.set('Content-Type', 'application/json');
      clonedRequest = clonedRequest.clone({headers});
    }

    return next.handle(clonedRequest);
  }

}
