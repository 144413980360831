import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {IMenuNode} from "@clavisco/menu";
import {CL_CHANNEL, ICLCallbacksInterface, ICLEvent, LinkerService, Register, Run, StepDown} from "@clavisco/linker";
import {Router} from "@angular/router";
import {GeneralConfigsService} from "../../../core/services/generalconfigs.service";
import {AlertsService, CLToastType} from "@clavisco/alerts";
import {PermissionService} from "../../../core/services/permission.service";
import {StorageService} from "../../../core/services/storage.service";
import {AuthenticationService} from "../../../core/services/authentication.service";
import {UserService} from "../../../core/services/user.service";
import {IToken} from "../../../core/interfaces/i-token";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, AfterViewInit {

  MenuId: string = 'menuId';

  Logo: string = '/assets/Logo-Claviscobck.png';

  Title: string = 'Bienvenido a Confort';


  Toggle: Boolean = true;

  MenuNodes: IMenuNode[] = [];


  callbacks: ICLCallbacksInterface<CL_CHANNEL> = {
    Callbacks: {},
    Tracks: []
  };
  txtaccion:string='';
  Nodes: IMenuNode[] = []
  listStoragePermissions:any;
  currentUser: string = '';
  constructor(private router: Router,
              @Inject('LinkerService') private linkerService: LinkerService,
              private generalConfig: GeneralConfigsService,
              private cdr: ChangeDetectorRef,
              private permissionService: PermissionService,
              private storageService: StorageService,
              private authService: AuthenticationService,
              private alertService: AlertsService,
              private userService:UserService) {
    if(this.storageService.GetItem('currentUser'))this.currentUser = JSON.parse(atob(this.storageService.GetItem('currentUser')!)).userName;
    this.MenuNodes=[
      {
        Key: "home",
        Description: "Inicio",
        Icon: "house",
        Route: "/home",
        Visible: true,
        Nodes: []
      },
      {
        Key: "M_Users",
        Description: "Usuarios",
        Icon: "person",
        Route: "/users",
        Visible: true,
        Nodes: []
      },
      {
        Key: "M_Roles",
        Description: "Rol",
        Icon: "engineering",
        Route: "/roles",
        Visible: true,
        Nodes: []
      },
      {
        Key: "M_AssignPerm",
        Description: "Asignación de Permisos",
        Icon: "assignment",
        Route: "/permissions",
        Visible: true,
        Nodes: []
      },
      {
        Key: "",
        Description: "Llamadas de servicios",
        Icon: "support_agent",
        Route: "/serviceCalls",
        Visible: true,
        Nodes: []
      },
      {
        Key: "logout",
        Description: "Cerrar sesión",
        Icon: "logout",
        Route: "/login",
        Visible: true,
        Nodes: []
      },
    ]
  }
  ngAfterViewInit():void{
    this.generalConfig.Name.subscribe((data) => {
      this.txtaccion = data;
      this.cdr.detectChanges();
    });
  }
  ngOnInit(): void {
    this.GetUsers()
    this.listStoragePermissions = this.storageService.GetPerms();
    this.Nodes = this.generalConfig.CheckMenuPermissions(this.listStoragePermissions,this.MenuNodes)
    Register(this.MenuId, CL_CHANNEL.OUTPUT, this.ClickMenuOption, this.callbacks);
    this.linkerService.Flow()?.pipe(
      StepDown<CL_CHANNEL>(this.callbacks),
    ).subscribe({
      next: callback => Run(callback.Target, callback, this.callbacks.Callbacks),
      error: error => console.log(`Error:`, error)
    });
  }
  public GetPermissionsByRole(roleId: number): void {
    this.permissionService.GetPermissionsByRole(roleId).subscribe(
      (data) => {
        if (data) {
          this.listStoragePermissions = data.Data.map(item => item.Name);
          this.storageService.SetPerms( this.listStoragePermissions);
          this.authService.currentPermissionsSubject.next(this.listStoragePermissions);
        } else {
          this.alertService.Toast({ message:'', type: CLToastType.WARNING });
        }
      })
  }

  GetUsers(): void {
    this.userService.Get(
     0,
      1,
      "",
      "",
      this.currentUser,
      true,
    ).subscribe({
        next: response => {
          if (response.Data) {
            this.GetPermissionsByRole(response.Data[0].RoleId)
          }else {
            this.alertService.Toast({message: 'Ocurrió un error al realizar la búsqueda', type: CLToastType.WARNING});
          }
        }, error: error => {
        console.log(error)
          this.alertService.Toast({message: 'Ocurrió un error al realizar la búsqueda', type: CLToastType.ERROR})
        }
      }
    )
  }
  ToggleMenu(): void {
    this.Toggle = !this.Toggle;
    this.linkerService.Publish({
      CallBack: CL_CHANNEL.DATA_LINE_1,
      Target: this.MenuId,
      Data: JSON.stringify(this.Toggle)
    } as ICLEvent);
  }
  ClickMenuOption = (_event: ICLEvent): void => {
    const menuNode: IMenuNode = JSON.parse(_event.Data) as IMenuNode;
    switch (menuNode.Key) {
      case "logout":
        this.authService.logout();
    }
  }
}
