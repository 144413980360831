import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";
import {StorageService} from "./storage.service";
import {IMenuNode} from "@clavisco/menu";
import {IPermissionsSelectedModel} from "../interfaces/i-permission-by-rol";

@Injectable({
  providedIn: 'root'
})
export class GeneralConfigsService {
  private dataUpdatedSource = new Subject<void>();
  private NameAction = new BehaviorSubject<string>('');
  Name = this.NameAction.asObservable();
  dataUpdated$ = this.dataUpdatedSource.asObservable();
  constructor(private storageService: StorageService) { }

  //Establece el nombre de la ventana activa en la barra de menú.
  SetNameAction(Action:string):void{
    this.NameAction.next(Action);
  }

  public CheckMenuPermissions(permissionList: string[], listMenu: IMenuNode[]): IMenuNode[] {
    const menuNodes: IMenuNode[] = [];
    //#se maneja el nombre del permiso igual al key del menú de clavis#
    listMenu.filter((element) =>  permissionList?.findIndex(item => item===element.Key)>-1  ||
      element.Key == "home" || element.Key == "logout")
      .forEach((element) => {
        const filteredNodes = element.Nodes ? this.CheckMenuPermissions(permissionList, element.Nodes) : [];
        if (filteredNodes.length > 0) {
          element.Nodes = filteredNodes;
        }
        menuNodes.push(element);
      });
    return menuNodes;
  }


  public CheckPermission(permissionName: string): boolean {
    let permissionList: string[] = this.storageService.GetPerms() ;

    if (permissionList && permissionList.length) {
      return permissionList.some((permission) => permission === permissionName);
    } else {
      return false;
    }
  }

  triggerDataUpdate() {
    this.dataUpdatedSource.next();
  }
}
