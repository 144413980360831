import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LoginComponent} from "./login.component";
import {RouterModule, Routes} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SharedModule} from "../../shared/shared.module";
import {OverlayService} from "@clavisco/overlay";
import {LinkerService} from "@clavisco/linker";
import {AlertsService} from "@clavisco/alerts";

export const routes: Routes = [
  { path: '', component: LoginComponent, pathMatch: 'full' }
];

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  providers: [{
    provide: 'OverlayService',
    useClass: OverlayService
  },
    {
      provide: 'LinkerService',
      useClass: LinkerService
    },
    {
      provide: 'AlertsService',
      useClass: AlertsService
    },
  ],
})
export class LoginModule { }
