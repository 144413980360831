import {Component, OnInit} from '@angular/core';
import {AppSettings, Settings} from "./app.settings";
import {Repository, Structures} from "@clavisco/core";
import {Login} from "@clavisco/login";
import {environment} from "../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

  title = 'cl_cf_cf_tsc';
  public settings:Settings;

  constructor(public appSettings : AppSettings,) {
    this.settings = this.appSettings.settings
  }
  ngOnInit(): void {

    Repository.Behavior.SetTokenConfiguration({
      token: 'Shared',
      setting: 'apiUrl',
      value: environment.ApiUrl,
    });

    Repository.Behavior.SetTokenConfiguration({
      token: Structures.Enums.TOKENS.LOGN,
      setting: 'overlaysConfiguration',
      value: JSON.stringify({
        loginMessage: 'Procesando, espere por favor',
        changePasswordMessage: 'Cambiando Contraseña',
        sendRecoverEmailMessage: 'Enviando Correo de Recuperación',
        recoverPasswordMessage: 'Actualizando Contraseña'
      } as Login.Structures.IOverlaysGlobalConfiguration)
    });
    Repository.Behavior.SetTokenConfiguration({
      token: Structures.Enums.TOKENS.LOGN,
      setting: 'changePassword',
      value: {
        endpointToRequest: `${environment.ApiUrl}Users/ChangePassword`
      } as Login.Structures.IChangePasswordConfig
    })

    Repository.Behavior.SetTokenConfiguration({
      token: Structures.Enums.TOKENS.LOGN,
      setting: 'recoverPassword',
      value: {
        endpointToRequest: `${environment.ApiUrl}Users/ChangeRecoveryPassword`
      }  as Login.Structures.IChangePasswordConfig
    })

    Repository.Behavior.SetTokenConfiguration({
      token: Structures.Enums.TOKENS.LOGN,
      setting: 'sendRecoverPasswordEmail',
      value: {
        urlVariableToReplaceWithEmail: '#EMAIL#',
        endpointToRequest: `${environment.ApiUrl}Users/#EMAIL#/RecoveryPassword`
      }
    })


  }

}
